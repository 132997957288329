import { startCase } from 'lodash'

export function makeActionManager(mesh, scene) {
    mesh.actionManager = mesh.actionManager || new BABYLON.ActionManager(scene)
}
export function meshClick(scene, mesh, callback) {
    makeActionManager(mesh, scene)
	mesh.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, callback)
	)
}

export function rgbToColor3(r, g, b) {
	return new BABYLON.Color3(r/255, g/255, b/255)
}


export function meshHover(scene, mesh) {
	makeActionManager(mesh, scene)
	mesh.renderOverlay = true
	mesh.overlayAlpha = 0
	// mesh.overlayColor = rgbToColor3(253, 190, 44) // saab yellow
	// mesh.overlayColor = rgbToColor3(0,103,165) // ag blue
	mesh.overlayColor = rgbToColor3(227,103,42) // ag orange
    

	mesh.actionManager.registerAction(
		new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, onPointerOver)
	)

	mesh.actionManager.registerAction(
		new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, onPointerOut)
	)

    function onPointerOver() {
        animateOverlay(scene, mesh, mesh.overlayAlpha, 1)
    }

    function onPointerOut() {
        animateOverlay(scene, mesh, mesh.overlayAlpha, 0)
    }
}


export function easeInOut(element) {
    const easing = new BABYLON.CubicEase()
    easing.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT)
    element.setEasingFunction(easing)
}

export function animateOverlay(scene, mesh, from, to) {
    const newAnimation = new BABYLON.Animation(
        'highlight_' + mesh.name,
        'overlayAlpha',
        60,
        BABYLON.Animation.ANIMATIONTYPE_FLOAT,
        BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    )

    newAnimation.setKeys([
        {frame: 0, value: from},
        {frame: 60 * 0.3, value: to}
    ])

    easeInOut(newAnimation)

    mesh.animations.push(newAnimation)
    scene.beginAnimation(mesh, 0, 60, false)
}


export function animateMeshPosition(scene, mesh, from, to) {
    const newAnimation = new BABYLON.Animation(
        'move_' + mesh.name,
        'position',
        60,
        BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
        BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    )

    newAnimation.setKeys([
        {frame: 0, value: from},
        {frame: 60, value: to}
    ])

    easeInOut(newAnimation)

    mesh.animations.push(newAnimation)
    scene.beginAnimation(mesh, 0, 60, false)
}





// export function make3DLabel(scene, text, mesh, offset) {
	
//     //#region Plane
//     // make plane for label
// 	const label_plane = new BABYLON.MeshBuilder.CreatePlane(mesh.name + "_label", {width: 2, height: 1}, scene)
//     // link plane position to mesh
//     label_plane.setParent(mesh)

//     // make new position vector using mesh's postion and any provided offset values
//     const center = getCenter(mesh), {x,y,z} = offset
//     label_plane.position = center.add(new BABYLON.Vector3(x,y,z))

//     // set billboard mode so plane faces camera at all times
//     label_plane.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL
		
//     // turn off pick handling on plane
//     // label_plane.isPickable = false
//     //#endregion Plane


//     const advancedTexture = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(label_plane, textureWidth, textureHeight, false)
//     advancedTexture.background = 'rgba(255, 0, 0, 0.05)'
//     advancedTexture.rootContainer.scaleX = advancedTexture.rootContainer.scaleY = window.devicePixelRatio
    
//     const textblock = new BABYLON.GUI.TextBlock('Test name', startCase(text))
//     textblock.fontSize = 48
//     // textblock.color = '#262626'
//     textblock.color = '#646464'
//     textblock.textVerticalAlignment = 1
//     textblock.top = -80
//     // textblock.heightInPixels = '80px'
//     // textblock.widthInPixels = '160px'
//     textblock.textWrapping = true
//     advancedTexture.addControl(textblock)


//     // const image = new BABYLON.GUI.Image("Hotspot", "assets/images/logo.png");
//     const image = new BABYLON.GUI.Image("Hotspot", "https://upload.wikimedia.org/wikipedia/commons/9/91/Location_dot_orange.svg");
//     image.detectPointerOnOpaqueOnly = true

//     // image.widthInPixels = image.heightInPixels = 100
//     image.widthInPixels = image.heightInPixels = 60
//     image.verticalAlignment = 1

//     advancedTexture.addControl(image);
    
//     // https://playground.babylonjs.com/#ZI9AK7#1214

//     return label_plane
// }

// export function make3DLabel2(scene, text, mesh, offset) {
	
//     // Make planes for elements
// 	const button_plane = new BABYLON.MeshBuilder.CreatePlane(mesh.name + "_button", {width: 0.3, height: 0.3}, scene)
//     button_plane.setParent(mesh)
    
//     // button_plane.rotation.x = Math.PI // patch for images applying upside down

// 	const label_plane = new BABYLON.MeshBuilder.CreatePlane(mesh.name + "_label", {width: 2, height: 1}, scene)
//     label_plane.setParent(button_plane)

//     // Set positions & behaviours
//     const center = getCenter(mesh), {x,y,z} = offset
//     button_plane.position = center.add(new BABYLON.Vector3(x, y, z))
    
//     button_plane.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL

//     label_plane.position = new BABYLON.Vector3(0, 0.9, 0)
//     label_plane.isPickable = false

    
//     const button_texture = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(button_plane, 400, 400, false)    
//     // button_texture.rootContainer.scaleX = window.devicePixelRatio
//     // button_texture.rootContainer.scaleY = window.devicePixelRatio

//     const label_texture = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(label_plane, 500, 250, false)
//     // label_texture.rootContainer.scaleX = window.devicePixelRatio
//     // label_texture.rootContainer.scaleY = window.devicePixelRatio
    
//     const button = makeButton(mesh.name, 'assets/images/buttons.svg#default')
//     const button_hover = makeButton(mesh.name + '_hover', 'assets/images/buttons.svg#hover')

//     // const button = makeButton(mesh.name, 'assets/images/logo.png')
//     // const button_hover = makeButton(mesh.name + '_hover', 'assets/images/logo.png')
    
//     button_texture.addControl(button_hover)
//     button_texture.addControl(button)
    
//     button.onPointerEnterObservable.add(() => {
//         button.alpha = 0
//     })
//     button.onPointerOutObservable.add(() => {
//         button.alpha = 1
//     })

//     const label = makeTextLabel(text)
//     label_texture.addControl(label)

//     // https://playground.babylonjs.com/#ZI9AK7#1214

//     return {
//         button_plane,
//         label_plane
//     }
// }

export function make3DLabel2(scene, name, text, position, parent = null) {

    /**
     * @param {object} scene Scene the new meshes are being added to
     * @param {string} name Name to be used in namong the new meshes
     * @param {string} text The wording of the label
     * @param {Vector3} position The position of the hotspot anchor point
     * @param {object} [parent = null] The parent mesh to position on
     * 
     * @return {object} planes The button and label planes
     */

    // Make planes for elements
	const button = new BABYLON.MeshBuilder.CreatePlane(`${name}_button`, {width: 0.3, height: 0.3}, scene)
    if (parent) {
        button.setParent(parent)
    }
	const label = new BABYLON.MeshBuilder.CreatePlane(`${name}_label`, {width: 2, height: 1}, scene)
    label.setParent(button)
    

    // Set positions & behaviours
    button.position = position
    button.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL
    label.position = new BABYLON.Vector3(0, 0.9, 0)
    label.isPickable = false

    const button_texture = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(button, 400, 400, false)
    const label_texture = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(label, 500, 250, false)
    
    const button_image = makeButton(name + '-orange_button', 'assets/images/buttons.svg#default')
    const button_image_hover = makeButton(name + '-orange_button_hover', 'assets/images/buttons.svg#hover')
    button_texture.addControl(button_image_hover)
    button_texture.addControl(button_image)
    button_image.onPointerEnterObservable.add(() => button_image.alpha = 0)
    button_image.onPointerOutObservable.add(() => button_image.alpha = 1)

    const label_text = makeTextLabel(text)
    label_texture.addControl(label_text)

    // https://playground.babylonjs.com/#ZI9AK7#1214

    return {
        button,
        label
    }
}

function makeButton(name, url) {
    url = url || 'https://upload.wikimedia.org/wikipedia/commons/9/91/Location_dot_orange.svg'
    const image = new BABYLON.GUI.Image(`${name}_icon_default`, url)

    image.detectPointerOnOpaqueOnly = true
    image.hoverCursor = 'pointer'
    image.widthInPixels = image.heightInPixels = 400
    image.paddingTop = image.paddingLeft = image.paddingRight = image.paddingBottom = '2px'
    
    image.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_CENTER
    image.stretch = BABYLON.GUI.Image.STRETCH_FILL
    image.isPointerBlocker = true
    return image
}

function makeTextLabel(text) {
    const textblock = new BABYLON.GUI.TextBlock(`${text}_text_label`, text)
    textblock.fontSize = 48
    textblock.color = '#646464'
    textblock.textVerticalAlignment = 1
    textblock.textWrapping = true
    return textblock
}


export function getCenter(mesh) {
    return mesh.getBoundingInfo().boundingBox.centerWorld
}


export function showNormals(scene, mesh, size, color, sc) {
    var normals = mesh.getVerticesData(BABYLON.VertexBuffer.NormalKind);
    var positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);      
    color = color || BABYLON.Color3.White();
    sc = sc || scene;
    size = size || 1;

    var lines = [];
    for (var i = 0; i < normals.length; i += 3) {
        var v1 = BABYLON.Vector3.FromArray(positions, i);
        var v2 = v1.add(BABYLON.Vector3.FromArray(normals, i).scaleInPlace(size));
        lines.push([v1.add(mesh.position), v2.add(mesh.position)]);
    }
    var normalLines = BABYLON.MeshBuilder.CreateLineSystem("normalLines", {lines: lines}, sc);
    normalLines.color = color;
    return normalLines;
}


export function scaleLinear(domain, range, x) {
    return range[0] + (range[1] - range[0]) * ((x - domain[0]) / (domain[1] - domain[0]))
}