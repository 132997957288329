<template>
    <div class="container">
        <canvas ref="canvas"/>
    </div>
</template>

<script>
import { merge } from 'lodash'
import { mapGetters, mapMutations } from 'vuex'
import { Scene, Engine, ArcRotateCamera, Vector3, SceneLoader } from 'babylonjs'
import {
    meshClick,
    easeInOut,
    make3DLabel2,
    getCenter,
} from '@/helpers'
import { animateMeshPosition, scaleLinear } from '@/helpers/babylon/interactions'

export default {
    props: {
        product: String,
    },

    computed: {
        ...mapGetters([
            'get_product',
            'products',
            'explode',
        ]),
        ...mapGetters('camera', {
            camera_settings: 'defaults',
        }),
    },

    data: () => ({
        canvas: undefined,
        scene: undefined,
        engine: undefined,
        show_canvas: true,
        userRadius: null,
        rotate_after_modal: false,
    }),

    watch: {
        // $route(n, o) {
        //     this.toggleVisible()
        // },

        product(n, o) {
            this.changeProduct(n)
            // this.setActiveProduct(n)
            // this.scene.activeCamera.radius = this.camera_settings.radius
        },

        explode(n, o) {
            if (n) {
                this.explodeProduct()
            } else {
                this.implodeProduct()
            }
        },
    },

    mounted() {
        this.createScene()

        // this.showDebug()

        this.scene.clearColor = new BABYLON.Color4(0, 0, 0, 0.0000001)
        
        this.engine.runRenderLoop(() => this.scene.render())
        window.addEventListener("resize", () => this.engine.resize())

        this.addListeners()
    },

    methods: {

        ...mapMutations([
            'setProductContainer',
            'setActiveProduct',
            'setProductLoadProgress',
        ]),


        changeProduct(product) {

            this.setActiveProduct(product)
            
            this.updateCameraRadius(product)
        },

        updateCameraRadius(product) {
            // Assign camera default settings from store module
            let productCameraSettings = this.$store.getters[`camera/${product}`] || this.$store.getters[`camera/10-ml-syringe`]
            
            productCameraSettings.radius = scaleLinear(
                [1920, 500],
                productCameraSettings.range,
                window.innerWidth
            )

            merge(this.scene.activeCamera, productCameraSettings)

            let buttons = this.scene.meshes.filter(m => m.name.match('_button'))

            let buttonScale = scaleLinear(
                [1920, 500],
                productCameraSettings.button_range,
                window.innerWidth
            )

            buttonScale = buttonScale < 1 ? 1 : buttonScale

            let scaleObject = new BABYLON.Vector3(buttonScale, buttonScale, buttonScale)
            buttons.forEach(button => {
                button.scaling = scaleObject
            })
        },

        explodeProduct() {
            this.animateMeshesTo('exploded')
        },
        implodeProduct() {
            this.animateMeshesTo('default')
        },

        animateMeshesTo(saved_location) {
            if (!['default', 'exploded'].includes(saved_location)) return
            this.scene.meshes.filter(mesh => mesh.saved_positions).forEach(mesh => {
                animateMeshPosition(
                    this.scene,
                    mesh,
                    mesh.position,
                    mesh.saved_positions[saved_location]
                )
            })
        },

        copyCamPositions() {
            let {alpha, beta, radius} = $vm0.scene.activeCamera
            console.log({alpha, beta, radius})
            copy({alpha, beta, radius})
        },

        addListeners() {
            this.$root.$on('modal-close', this.onModalClose)
            this.$root.$on('interaction', i => {
                if (i == 'rotate') {
                    this.toggleRotate()
                }
                
                if (i == 'antt') {
                    this.rotate_after_modal = this.scene.activeCamera.useAutoRotationBehavior
                    this.disableAutoRotate()
                    this.$store.commit('setActiveModal', i)
                }

                if (i == 'explode') {
                    this.$store.commit('toggleExplode')
                }

                if (i == 'reference') {
                    this.$store.commit('setReferenceModal', true)
                }

                // if (i == 'prescribing-information') {
                //     this.rotate_after_modal = this.scene.activeCamera.useAutoRotationBehavior
                //     this.disableAutoRotate()
                //     this.$store.commit('setActiveModal', i)
                // }
            })


            window.addEventListener("resize", () => {
                this.updateCameraRadius(this.product)
            })
        },

        async createScene() {
            this.engine = new Engine(this.$refs['canvas'], true)
            this.engine.setHardwareScalingLevel(1 / window.devicePixelRatio)
            // this.engine.displayLoadingUI()
            this.$store.commit('setLoadingScreen', true)


            // This creates a basic Babylon Scene object (non-mesh)
            this.scene = new Scene(this.engine)
            // This creates and positions a free camera (non-mesh)
            let camera = new ArcRotateCamera("Camera", 0, Math.PI/2, 12, new Vector3(0, 5, 0), this.scene)

            // // Assign camera default settings from store module
            // merge(camera, this.camera_settings)

            camera.setTarget(BABYLON.Vector3.Zero())
            camera.attachControl(this.$refs['canvas'], true)
            // Disable camera panning
            camera.panningSensibility = 0;

            await this.loadSyringe()
            await this.load5mlSyringe()
            await this.loadBlisterPack()
            await this.loadPouch()

            // Assign camera default settings from store module
            merge(camera, this.camera_settings)
            // this.enableAutoRotate()

            // Switch to product defined by initial url
            this.changeProduct(this.product)
            // this.setActiveProduct(this.product)

            // this.engine.hideLoadingUI()
            this.$store.commit('setLoadingScreen', false)

        },

        toggleRotate() {
            if (this.scene.activeCamera.useAutoRotationBehavior) {
                this.disableAutoRotate()
            } else {
                this.enableAutoRotate()
            }
        },

        enableAutoRotate() {
            this.scene.activeCamera.useAutoRotationBehavior = true
            merge(
                this.scene.activeCamera.autoRotationBehavior,
                this.camera_settings.autoRotationBehavior
            )
            this.$store.commit('setAutoRotate', this.scene.activeCamera.useAutoRotationBehavior)
        },

        disableAutoRotate() {
            this.scene.activeCamera.useAutoRotationBehavior = false
            this.$store.commit('setAutoRotate', this.scene.activeCamera.useAutoRotationBehavior)
        },

        async triggerModal(target) {
            await this.zoomCameraToMesh(getCenter(target.mesh))
            this.rotate_after_modal = this.scene.activeCamera.useAutoRotationBehavior
            this.disableAutoRotate()
            this.$store.commit('setActiveModal', target.modal_id)
        },

        onModalClose() {
            this.returnCameraToUser()
            if (this.rotate_after_modal) {
                this.enableAutoRotate()
            }
        },

        zoomCameraToMesh(position) {
            if (!position) return
            const camera = this.scene.activeCamera
            const { radius, lowerRadiusLimit } = camera
            const frameRate = 10


            // Save current user zoom level if moving away from origin, to allow return
            this.userRadius = radius
            
            //#region Camera target change
            // Instantiate animation
            const focusCamera = new BABYLON.Animation("focusCamera", "target", frameRate, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE);
            
            // Specify the animation keys
            focusCamera.setKeys([
                { frame: 0, value: camera.getTarget() },
                { frame: frameRate, value: position }
            ])
            
            // Apply easing to animation
            easeInOut(focusCamera)
            //#endregion
            
            //#region Camera zoom change
            // Instantiate animation
            const zoomCamera = new BABYLON.Animation("zoomCamera", "radius", frameRate, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE);
            
            // Specify the animation keys
            zoomCamera.setKeys([
                { frame: 0, value: radius },
                { frame: frameRate, value: lowerRadiusLimit }
            ])

            // Apply easing to animation
            easeInOut(zoomCamera)

            //#endregion
            
            
            // Trigger the animation
            return new Promise((resolve, reject) => {
                this.scene.beginDirectAnimation(camera, [focusCamera, zoomCamera], 0, frameRate, false, 2, resolve)
            })
        },

        returnCameraToUser() {
            if (!this.userRadius) return
            const camera = this.scene.activeCamera
            const { radius } = camera
            const frameRate = 10


            //#region Camera target change
            // Instantiate animation
            const focusCamera = new BABYLON.Animation("focusCamera", "target", frameRate, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE);
            
            // Specify the animation keys
            focusCamera.setKeys([
                { frame: 0, value: camera.getTarget() },
                { frame: frameRate, value: BABYLON.Vector3.Zero }
            ])
            
            // Apply easing to animation
            easeInOut(focusCamera)
            //#endregion
            
            //#region Camera zoom change
            // Instantiate animation
            const zoomCamera = new BABYLON.Animation("zoomCamera", "radius", frameRate, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE);
            
            // Specify the animation keys
            zoomCamera.setKeys([
                { frame: 0, value: radius },
                { frame: frameRate, value: this.userRadius }
            ])

            this.userRadius = null

            // Apply easing to animation
            easeInOut(zoomCamera)

            //#endregion
            
            
            // Trigger the animation
            return new Promise((resolve, reject) => {
                this.scene.beginDirectAnimation(camera, [focusCamera, zoomCamera], 0, frameRate, false, 2, resolve)
            })
        },



        //#region Dev methods
        showDebug() {
            this.scene.debugLayer.show({
                embedMode: true,
            })
        },
        hideDebug() {
            this.scene.debugLayer.hide()
        },
        toggleDebugLayer() {
            this.scene.debugLayer.isVisible() ? 
                this.hideDebug() :
                this.showDebug();  
        },
        //#endregion

        //#region Mesh loading
        async loadSyringe() {
            return new Promise(async (resolve, reject) => {
                const syringe_container = await SceneLoader.LoadAssetContainerAsync(
                    "assets/3d/with-textures-2/Syringe/",
                    "Aguettant 360 Syringe Interactive Model V13.babylon",
                    this.scene,
                    p => {
                        console.log('Syringe')
                        console.log((p.loaded / p.total) * 100 + '%')
                        this.setProductLoadProgress({slug: '10-ml-syringe', progress: p.loaded / p.total})
                    }
                )
                syringe_container.addAllToScene()

                const MESH_SYRINGE_WHOLE = this.scene.getMeshByName('Aguettant_Syringe_Whole')
                const MESH_LABEL = this.scene.getMeshByName('label01')
                const MESH_BUNG = this.scene.getMeshByName('bung01')
                const MESH_PLUNGER = this.scene.getMeshByName('plunger02')
                const MESH_BODY = this.scene.getMeshByName('handlebody01')
                const MESH_LID = this.scene.getMeshByName('lid02')
                const MESH_LIQUID = this.scene.getMeshByName('liquid01')
                
                

                MESH_SYRINGE_WHOLE.position.y = -0.85
                
                const LIGHT_DEFAULT = this.scene.getLightByName('Default light')
                LIGHT_DEFAULT.intensity = .2
                let light = new BABYLON.HemisphericLight("syringe-up", new BABYLON.Vector3(0, -1, 0), this.scene)
                light.intensity = .8
                let light2 = new BABYLON.HemisphericLight("syringe-down-rear", new BABYLON.Vector3(0, 1, -1), this.scene)
                light2.intensity = .8
                let light3 = new BABYLON.HemisphericLight("syringe-down-front", new BABYLON.Vector3(0, 1, 1), this.scene)
                light3.intensity = .8
                

                syringe_container.lights.push(
                    light,
                    light2,
                    light3
                )

                //#region Material adjustments

                MESH_BUNG.setParent(MESH_PLUNGER)
                
                merge(MESH_BODY.material, {
                    alpha: .7,
                    diffuseColor: new BABYLON.Color3(0.6, 0.6862745098039216, 0.7137254901960784),
                    specularColor: new BABYLON.Color3(.25, .25, .25),
                    specularPower: 110
                })
                merge(MESH_BUNG.material, {
                    specularColor: new BABYLON.Color3(.2, .2, .2),
                    specularPower: 100
                })
                
                // merge(MESH_LID, {
                //     alphaIndex: 1
                // })
                merge(MESH_LID.material, {
                    alpha: 1,
                    backFaceCulling: false,
                    diffuseColor: new BABYLON.Color3(0.16862745098039217, 0.403921568627451, 0.5372549019607843),
                    specularColor: new BABYLON.Color3(.2, .2, .2),
                    specularPower: 75
                })
                
                // MESH_LABEL.position.y = -0.005

                merge(MESH_LABEL, {
                    position: { y: -0.005 },
                    material: {
                        alpha: 1,
                        backFaceCulling: false,
                        opacityTexture: null,
                        diffuseTexture: {
                        hasAlpha: true
                        }
                    }
                })
                // MESH_LABEL.position.y = -0.005

                // merge(MESH_LABEL.material, {
                //     alpha: 1,
                //     backFaceCulling: false,
                //     opacityTexture: null,
                //     diffuseTexture: {
                //         hasAlpha: true
                //     }
                // })

                merge(MESH_LIQUID, {
                    alphaIndex: -1, 
                    material: {
                        alpha: .4,
                        diffuseColor: new BABYLON.Color3(162/255, 180/255, 191/255)
                    }
                })

                //#endregion
                
                const parts = [
                    { mesh: MESH_PLUNGER,   exploded: [ -3, 0, 0 ] },
                    { mesh: MESH_BODY,      exploded: [ 0, 0, 0 ] },
                    { mesh: MESH_LIQUID,    exploded: [ 0, -2.5, 0 ] },
                    { mesh: MESH_LABEL,     exploded: [ 0, 2.5, 0 ] },
                    { mesh: MESH_LID,       exploded: [ 3, 0, 0 ] },
                ]

                function storeExplodedPosition(part) {
                    part.mesh.saved_positions = {
                        'default': part.mesh.position,
                        'exploded': new BABYLON.Vector3(...part.exploded),
                    }
                }
                parts.forEach(storeExplodedPosition)



                let hotspots = [
                    // { 
                    //     name: 'Ready to administer',
                    //     id: `ready-to-administer`,
                    //     position: [-4, 1.5, 0],
                    //     parent: 'plunger01'
                    // },
                    // { 
                    //     name: 'Pre-filled',
                    //     id: `pre-filled`,
                    //     position: [3, -2, 0],
                    //     parent: 'liquid01'
                    // },
                    { 
                        id: `pre-filled-ready-to-administer`,
                        name: `Pre-filled and ready-to-administer`,
                        position: [3, -1.75, 0],
                        parent: MESH_LIQUID
                    },
                    { 
                        name: 'Needle-free',
                        id: `needle-free`,
                        position: [8.5, -1.25, 0],
                        parent: MESH_BODY
                    },
                    { 
                        name: 'Polypropylene material',
                        id: `polypropylene-material`,
                        position: [-1, 3, 0],
                        parent: MESH_BODY
                    },
                    { 
                        name: 'Clear labelling',
                        id: `clear-labelling`,
                        position: [3, 3, 0],
                        parent: MESH_LABEL
                    },
                    { 
                        name: 'Tamper-evident seal',
                        id: `tamper-evident-seal`,
                        position: [7.5, 3, 0],
                        parent: MESH_LABEL
                    },
                ]

                hotspots.forEach(hotspot => {

                    // Make the button & label
                    const { button, label } = make3DLabel2(
                        this.scene,
                        hotspot.id,
                        hotspot.name,
                        new BABYLON.Vector3(...hotspot.position),
                        hotspot.parent,
                    )

                    // Add meshed to container
                    syringe_container.meshes.push(button, label)

                    // Add interactions
                    let onClick = () => this.triggerModal({ mesh: button, modal_id: hotspot.id })
                    meshClick(this.scene, button, onClick)

                })

                this.setProductContainer({ slug: '10-ml-syringe', container: syringe_container })

                resolve()
            })
        },

        async load5mlSyringe() {
            return new Promise(async (resolve, reject) => {
                const container = await SceneLoader.LoadAssetContainerAsync(
                    "assets/3d/with-textures-2/Syringe-5ml/",
                    "Aguettant 360 Syringe Interactive Model 5ml V2.babylon",
                    this.scene,
                    p => {
                        console.log('Syringe')
                        console.log((p.loaded / p.total) * 100 + '%')
                        this.setProductLoadProgress({slug: '5-ml-syringe', progress: p.loaded / p.total})
                    }
                )

                function getMesh(container, name) {
                    return container.meshes.find(m => m.name == name)
                }

                const MESH_SYRINGE_WHOLE = getMesh(container, 'Aguettant_Syringe_5ml')
                const MESH_LABEL = getMesh(container, 'label01')
                const MESH_BUNG = getMesh(container, 'bung01')
                const MESH_PLUNGER = getMesh(container, 'plunger02')
                const MESH_BODY = getMesh(container, 'handlebody01')
                const MESH_LID = getMesh(container, 'lid02')
                const MESH_LIQUID = getMesh(container, 'liquid01')
                
                MESH_SYRINGE_WHOLE.position.y = -0.85
                
                const LIGHT_DEFAULT = container.lights.find(l => l.name == 'Default light')
                LIGHT_DEFAULT.intensity = .2
                let light = new BABYLON.HemisphericLight("syringe-up", new BABYLON.Vector3(0, -1, 0), this.scene)
                light.intensity = .8
                let light2 = new BABYLON.HemisphericLight("syringe-down-rear", new BABYLON.Vector3(0, 1, -1), this.scene)
                light2.intensity = .8
                let light3 = new BABYLON.HemisphericLight("syringe-down-front", new BABYLON.Vector3(0, 1, 1), this.scene)
                light3.intensity = .8
                

                container.lights.push( light, light2, light3 )

                //#region Material adjustments

                MESH_BUNG.setParent(MESH_PLUNGER)
                
                merge(MESH_BODY.material, {
                    alpha: .7,
                    diffuseColor: new BABYLON.Color3(0.6, 0.6862745098039216, 0.7137254901960784),
                    specularColor: new BABYLON.Color3(.25, .25, .25),
                    specularPower: 110
                })
                merge(MESH_BUNG.material, {
                    specularColor: new BABYLON.Color3(.2, .2, .2),
                    specularPower: 100
                })
                
                merge(MESH_LID.material, {
                    alpha: 1,
                    backFaceCulling: false,
                    diffuseColor: new BABYLON.Color3(0.16862745098039217, 0.403921568627451, 0.5372549019607843),
                    specularColor: new BABYLON.Color3(.2, .2, .2),
                    specularPower: 75
                })
                
                merge(MESH_LABEL, {
                    position: { y: -0.003 },
                    material: {
                        alpha: 1,
                        backFaceCulling: false,
                        opacityTexture: null,
                        diffuseTexture: {
                        hasAlpha: true
                        }
                    }
                })

                merge(MESH_LIQUID, {
                    alphaIndex: -1, 
                    material: {
                        alpha: .4,
                        diffuseColor: new BABYLON.Color3(162/255, 180/255, 191/255)
                    }
                })

                //#endregion
                
                const parts = [
                    { mesh: MESH_PLUNGER,   exploded: [ -3, 0, 0 ] },
                    { mesh: MESH_BODY,      exploded: [ 0, 0, 0 ] },
                    { mesh: MESH_LIQUID,    exploded: [ 0, -2.5, 0 ] },
                    { mesh: MESH_LABEL,     exploded: [ 0, 2.5, 0 ] },
                    { mesh: MESH_LID,       exploded: [ 3, 0, 0 ] },
                ]

                function storeExplodedPosition(part) {
                    part.mesh.saved_positions = {
                        'default': part.mesh.position,
                        'exploded': new BABYLON.Vector3(...part.exploded),
                    }
                }
                parts.forEach(storeExplodedPosition)



                let hotspots = [
                    { 
                        id: `pre-filled-ready-to-administer`,
                        name: `Pre-filled and ready-to-administer`,
                        position: [3, -1.75, 0],
                        parent: MESH_LIQUID
                    },
                    { 
                        name: 'Needle-free',
                        id: `needle-free`,
                        position: [6.5, -1.25, 0],
                        parent: MESH_BODY
                    },
                    { 
                        name: 'Polypropylene material',
                        id: `polypropylene-material`,
                        position: [-1, 3, 0],
                        parent: MESH_BODY
                    },
                    { 
                        name: 'Clear labelling',
                        id: `clear-labelling`,
                        position: [3, 3, 0],
                        parent: MESH_LABEL
                    },
                    { 
                        name: 'Tamper-evident seal',
                        id: `tamper-evident-seal`,
                        position: [5.5, 3, 0],
                        parent: MESH_LABEL
                    },
                ]

                hotspots.forEach(hotspot => {

                    // Make the button & label
                    const { button, label } = make3DLabel2(
                        this.scene,
                        hotspot.id,
                        hotspot.name,
                        new BABYLON.Vector3(...hotspot.position),
                        hotspot.parent,
                    )

                    // Add meshed to container
                    container.meshes.push(button, label)

                    // Add interactions
                    let onClick = () => this.triggerModal({ mesh: button, modal_id: hotspot.id })
                    meshClick(this.scene, button, onClick)

                })

                this.setProductContainer({ slug: '5-ml-syringe', container })

                resolve()
            })
        },

        async loadBlisterPack() {
            return new Promise(async (resolve, reject) => {
                const container = await SceneLoader.LoadAssetContainerAsync(
                    "assets/3d/with-textures-2/Blister Pack/",
                    "Aguettant 360 Syringe Blister Pack Interactive Model V7.babylon",
                    this.scene,
                    p => {
                        console.log('Blister')
                        console.log((p.loaded / p.total) * 100 + '%')
                        this.setProductLoadProgress({slug: 'blister-pack', progress: p.loaded / p.total})
                    }
                )

                function getMesh(container, name) {
                    return container.meshes.find(m => m.name == name)
                }

                const MESH_BLISTER_PACK = getMesh(container, 'Aguettant_Blister_Pack')
                const MESH_SYRINGE_WHOLE = getMesh(container, 'Aguettant_Syringe_Whole2')
                const MESH_BLISTER_BODY = getMesh(container, 'blisterbody01')
                const MESH_SYRINGE_LABEL = getMesh(container, 'label01')
                const MESH_SYRINGE_LID = getMesh(container, 'lid02')


                const LIGHT_DEFAULT = container.lights.find(l => l.name == 'Default light')
                LIGHT_DEFAULT.intensity = .2
                let light = new BABYLON.HemisphericLight("blister-up", new BABYLON.Vector3(0, -1, 0), this.scene)
                light.intensity = .8
                let light2 = new BABYLON.HemisphericLight("blister-down-rear", new BABYLON.Vector3(0, 1, -1), this.scene)
                light2.intensity = .8
                let light3 = new BABYLON.HemisphericLight("blister-down-front", new BABYLON.Vector3(0, 1, 1), this.scene)
                light3.intensity = .8
                

                container.lights.push(
                    light,
                    light2,
                    light3
                )

                // let light = new BABYLON.HemisphericLight("blister-light", new BABYLON.Vector3(-1, -1, 0), this.scene)
                // light.intensity = .6
                // let light2 = new BABYLON.HemisphericLight("blister-light2", new BABYLON.Vector3(1, 1, 0), this.scene)
                // light2.intensity = .6

                // container.lights.push(light, light2)
                
                // Reverse Y of blister (Get Jack to correct in maya)
                // container.meshes.find(mesh => mesh.name == 'Aguettant_Blister_Pack_Whole1').scaling.y = -1

                // const syringe_container = await SceneLoader.LoadAssetContainerAsync("assets/3d/w-testures/Syringe/", "Aguettant 360 Syringe Interactive Model V11.babylon", this.scene)
                // container.meshes.push(...syringe_container.meshes)

                // getMesh(syringe_container, 'Aguettant_Syringe_Whole').position.z = 0
                // getMesh(container, 'Aguettant_Blister_Pack').position.z = 0
                
                MESH_BLISTER_PACK.position = new BABYLON.Vector3(0, -1, 1)
                MESH_SYRINGE_WHOLE.position.y += 0.1

                merge(MESH_SYRINGE_LID, {
                    alphaIndex: 1,
                    material: {
                        alpha: 1,
                        backFaceCulling: false,
                        diffuseColor: new BABYLON.Color3(0.16862745098039217, 0.403921568627451, 0.5372549019607843),
                        specularColor: new BABYLON.Color3(.2, .2, .2),
                        specularPower: 75
                    },
                })
                merge(MESH_SYRINGE_LABEL.material, {
                    alpha: 1,
                    backFaceCulling: false,
                    opacityTexture: null,
                    diffuseTexture: {
                        hasAlpha: true
                    }
                })
                
                //#region Material adjustments


                // merge(getMaterial(syringe_container, 'Babylon_Material_Test_V1:phong2'), {
                //     alpha: .65,
                //     diffuseColor: new BABYLON.Color3(0.6, 0.6862745098039216, 0.7137254901960784)
                // })
                
                
                // merge(getMesh(syringe_container, 'lid01').material, {
                //     alpha: 1,
                //     backFaceCulling: false,
                //     diffuseColor: new BABYLON.Color3(0.16862745098039217, 0.403921568627451, 0.5372549019607843)
                // })
                
                // getMesh(syringe_container, 'label01').alphaIndex = 1
                // merge(getMesh(syringe_container, 'label01').material, {
                //     alpha: 1,
                //     backFaceCulling: false,
                // })
                
                // merge(MESH_BLISTER_BODY.material, {
                //     alpha: .7,
                //     backFaceCulling: false,
                //     diffuseColor: new BABYLON.Color3(
                //         194/255,
                //         203/255,
                //         208/255,
                //     )
                // })
                MESH_BLISTER_BODY.flipFaces(true)
                merge(MESH_BLISTER_BODY, {
                    alphaIndex: 2,
                    material: {
                        alpha: .4,
                        separateCullingPass: true,
                        specularPower: 10,
                        // diffuseColor: new BABYLON.Color3(0.6078431372549019, 0.6274509803921569, 0.6392156862745098)
                        diffuseColor: new BABYLON.Color3(
                            194/255,
                            203/255,
                            208/255,
                        )
                    },
                })
                // MESH_BLISTER_BODY.alphaIndex = 2

                //#endregion

                let hotspots = [
                    { name: `Tyvek label`, position: [-7, 2, 0], id: `tyvek-label` },
                    { name: `Terminally sterilised`, position: [8, 2, 0], id: `terminally-sterilised` },
                    { name: `Clear labelling`, position: [0, 2, 0], id: `clear-labelling` },
                    // { name: `Open when needed`, position: [-6, 2, 0], id: `open-when-needed` },
                    { name: `Clear tray`, position: [-3, -2.4, 0], id: `clear-tray` },
                ]

                hotspots.forEach(hotspot => {

                    // Make the button & label
                    const { button, label } = make3DLabel2(this.scene, hotspot.id, hotspot.name, new BABYLON.Vector3(...hotspot.position))
                    
                    // Add meshed to container
                    container.meshes.push(button, label)

                    // Add interactions
                    meshClick(this.scene, button, () => this.triggerModal({
                        mesh: button,
                        modal_id: hotspot.id
                    }))

                })

                this.setProductContainer({ slug: 'blister-pack', container })
                resolve()
            })
        },

        async loadPouch() {
            return new Promise(async (resolve, reject) => {
                const container = await SceneLoader.LoadAssetContainerAsync(
                    "assets/3d/with-textures-2/Foil Pouch/",
                    "Aguettant 360 Syringe Foil Pouch Interactive Model V7.babylon",
                    this.scene,
                    p => {
                        console.log('Pouch')
                        console.log((p.loaded / p.total) * 100 + '%')
                        this.setProductLoadProgress({slug: 'foil-pouch', progress: p.loaded / p.total})
                    }
                )

                function getMesh(container, name) {
                    return container.meshes.find(m => m.name == name)
                }

                const MESH_PACKAGE_WHOLE = getMesh(container, 'Aguettant_Foil_Package_Whole')
                const MESH_PACKAGE_MAIN = getMesh(container, 'Foil_Package_Main_003')
                const MESH_PACKAGE_END_1 = getMesh(container, 'Foil_Package_End_001')
                const MESH_PACKAGE_END_2 = getMesh(container, 'Foil_Package_End_002')
                

                let light = new BABYLON.HemisphericLight("pouch-light", new BABYLON.Vector3(-1, -1, 0), this.scene)
                light.intensity = .6

                container.lights.push(light)

                MESH_PACKAGE_WHOLE.position = new BABYLON.Vector3(0, -1, 2)
                
                // Modify specular colour for purple sections of packet to feel more like foil
                ;[MESH_PACKAGE_MAIN, MESH_PACKAGE_END_1, MESH_PACKAGE_END_2].forEach(mesh => {
                    merge(mesh, {
                        material: {
                            subMaterials: [ { specularColor: new BABYLON.Color3(.24, .24, .24) } ]
                        },
                    })
                })

                let hotspots = [
                    { position: [-7, 2, 0], id: `tamper-evident-packaging`, name: `Tamper-evident packaging` },
                    { position: [-3, 2, 0], id: `easy-to-open`, name: `Easy to open` },
                    { position: [7, 2, 0], id: `shelf-life`, name: `Shelf life` },
                ]

                hotspots.forEach(hotspot => {

                    // Make the button & label
                    const { button, label } = make3DLabel2(this.scene, hotspot.id, hotspot.name, new BABYLON.Vector3(...hotspot.position))
                    
                    // Add meshed to container
                    container.meshes.push(button, label)

                    // Add interactions
                    meshClick(this.scene, button, () => this.triggerModal({
                        mesh: button,
                        modal_id: hotspot.id
                    }))

                })


                this.setProductContainer({ slug: 'foil-pouch', container })
                resolve()
            })
        },
        //#endregion
        
    },
}


</script>

<style lang="scss" scoped>
.container {
    // background-image: 
    //     linear-gradient(rgba($Aguettant_Sky_Blue, .35), rgba($Aguettant_Sky_Blue, .05)),
    //     linear-gradient(rgba(white, 1), rgba(white, .45)),
    //     url("~@/assets/18010.jpg");
    background-image: 
        // linear-gradient(rgba(white, .9), rgba(white, .2)),
        url("~@/assets/grey origami.svg");
        // url("~@/assets/grey origami (1).svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

</style>